import React from "react";

function Input(props) {
  return (
    <div className="mt-4">
      <label name={props?.name} className="pl-4">
        {props?.label} {!props?.optional && "*"}
      </label>
      {props?.textarea ? (
        <textarea
          name={props?.name}
          rows="7"
          className="appearance-none w-full mt-2 py-2 px-1 leading-tight focus:outline-none  border border-theme block  rounded-md"
          onChange={(e) => {
            props.onChange(e);
          }}
          value={props?.value}
        />
      ) : (
        <input
          name={props?.name}
          type="text"
          className="w-full rounded-md h-12 border-theme mt-2 border block appearance-none py-1 px-2 leading-tight focus:outline-none"
          onChange={(e) => {
            props.onChange(e);
          }}
          value={props?.value}
        />
      )}
    </div>
  );
}

export default Input;
