import React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { FORM } from "../helpers/api";
import { makePostRequest } from "../helpers/requests";
import { Formik } from "formik";
import SEO from "../components/seo";
import Input from "../components/Input";
import * as Yup from "yup";
import { withSnackbar } from "react-simple-snackbar";

const contactSchema = Yup.object().shape({
  full_name: Yup.string().required("Full name is required"),
  email_address: Yup.string()
    .email("Email address must be valid")
    .required("Email address is required"),
  department: Yup.string().required("Department is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUs = ({
  openSnackbar,
  data: {
    form,
    Contact: { edges },
    merlinWebsite: { websiteId },
    page: {
      frontmatter: { contact_heading, contact_description },
    },
  },
}) => {
  const handleSubmit = async (values, actions) => {
    try {
      const postData = {
        formId: form?._id,
        sections: Object.keys(values).map((sectionId) => {
          const formSection = form?.sections?.find(
            ({ _id }) => _id === sectionId
          );

          let sectionData = {
            _id: sectionId,
            fields: [],
          };

          for (let fieldId in values[sectionId]) {
            if (values[sectionId].hasOwnProperty(fieldId)) {
              const formSectionField = formSection?.fields?.find(
                ({ _id }) => _id === fieldId
              );

              sectionData.fields.push({
                _id: fieldId,
                value:
                  formSectionField?.type === "dropdown" ||
                  formSectionField?.type === "radio"
                    ? formSectionField?.options?.find(
                        ({ _id }) => _id === values[sectionId][fieldId]
                      )?.label
                    : values[sectionId][fieldId],
              });
            }
          }

          return sectionData;
        }),
      };

      const { data: formData } = await makePostRequest(FORM, postData, {
        websiteId,
      });
      openSnackbar("Enquiry sent successfully");
    } catch (error) {
      error !== "cancelled" &&
        openSnackbar(
          error?.errorMessage ?? "An error occurred sending enquiry form"
        );
    }
  };
  const getFields = () => {
    const sections = {};

    for (let section of form?.sections) {
      sections[section._id] = {};

      for (let field of section?.fields) {
        sections[section._id][field?._id] = undefined;
      }
    }

    return sections;
  };
  const renderValidationField = ({ type, label, optional, validation }) => {
    let value;

    switch (type) {
      case "string":
        value = Yup.string();
        if (validation?.type === "text" && validation?.condition === "email") {
          value = value.email(validation?.error ?? "Must be an email address.");
        }
        if (!optional) value = value.required(`${label} is required.`);
        return value;
      case "paragraph":
      case "dropdown":
        // case 'radio':
        value = Yup.string();
        if (!optional) value = value.required(`${label} is required.`);
        return value;
      // case 'checkbox':
      //     value = Yup.array().of(Yup.string());
      //     if (!optional) value = value.required(`${label} is required.`);
      //     return value;
    }

    return Yup.mixed().nullable();
  };

  const renderValidationFields = () => {
    const schema = {};

    Object.keys(form?.sections).forEach((sectionKey) => {
      const section = form?.sections[sectionKey];
      const sectionId = section?._id;

      const fieldSchema = {};

      Object.keys(section?.fields).forEach((fieldKey) => {
        const field = section?.fields[fieldKey];
        const fieldId = field?._id;

        fieldSchema[fieldId] = renderValidationField(field);
      });

      schema[sectionId] = Yup.object().shape(fieldSchema);
    });

    return Yup.object().shape(schema);
  };

  return (
    <Layout>
      <SEO
        seo={{
          title: "Contact",
          description:
            "Please fill out the form below and we will get back to you as soon as possible",
          previewURL: "https://merlinpanel.com/contact/showcase/preview.png",
        }}
      />
      <div className="mt-32 md:max-w-6xl mx-auto px-5 md:px-8">
        <div className="ContactUs">
          <div className="py-12">
            <div className="flex flex-col items-center w-full">
              <div className="w-1/2 my-0 mx-auto flex justify-center">
                <img
                  src={edges[0].node.publicURL}
                  alt="merlins-holding-hands"
                />
              </div>
              <div className="text-center mt-8">
                <h3 className=" text-4xl md:text-6xl font-bold">
                  {contact_heading}
                </h3>
                <p className="text-lg text-textcolor my-4">
                  {contact_description}
                </p>
              </div>
            </div>
            <Formik
              initialValues={getFields()}
              validationSchema={renderValidationFields()}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <div>
                  <ContactFormikForm {...props} form={form} />
                  <div
                    className={`mt-8 flex flex-col md:flex-row justify-center md:justify-end
                     w-1/2`}
                  >
                    <button
                      disabled={props.isSubmitting}
                      onClick={props.handleSubmit}
                      type="submit"
                      className="capitalize focus:outline-none bg-theme text-white text-sm btn-purple-on-white p-3 w-32 h-12"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withSnackbar(ContactUs);

const ContactFormikForm = ({
  values,
  handleChange,
  setFieldValue,
  touched,
  errors,
  form,
}) => {
  return (
    <div className="w-full md:w-1/2 ">
      {form?.sections?.map((section) => (
        <React.Fragment key={section?._id}>
          {section?.fields?.map((field) => {
            const { _id, type, hint, label, optional } = field;
            const name = `${section._id}.${_id}`;

            const fieldProps = {
              name,
              optional: optional,
              label: label,
              hint: hint,
              value: values?.[section?._id]?.[_id],
              onChange: handleChange,
              id: _id,
            };

            console.log({ values });

            return (
              <React.Fragment key={_id}>
                {type === "checkbox" && (
                  <div className="my-8">
                    <label for="existing-member" className="pl-4">
                      {fieldProps.label} {!fieldProps.optional && "*"}
                    </label>
                    <input
                      name={fieldProps.name}
                      type="checkbox"
                      className="border block appearance-none mt-2 py-1 px-2 leading-tight focus:outline-none ml-4"
                      onChange={(e) => {
                        fieldProps.onChange(e);
                      }}
                    />
                  </div>
                )}
                {type === "string" && <Input {...fieldProps} />}
                {type === "paragraph" && <Input {...fieldProps} textarea />}
                {/*{type === 'radio' && <Radios {...fieldProps} />}*/}
                {/*{type === 'checkbox' && <Checkboxes {...fieldProps} />}*/}
                {/* {type === "dropdown" && (
                  <Select {...fieldProps}>
                    {field?.options?.map((option) => (
                      <Select.Option
                        key={option?._id}
                        value={option?._id}
                        label={option?.label}
                      />
                    ))}
                  </Select>
                )} */}

                {touched?.[section?._id]?.[_id] &&
                  errors?.[section?._id]?.[_id] && (
                    <span className="text-red-700">
                      <span className="my-4">
                        {errors?.[section?._id]?.[_id]}
                      </span>
                    </span>
                  )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
};

export const query = () => graphql`
  query ContactQuery($path: String!) {
    Contact: allFile(
      filter: { absolutePath: { regex: "/merlincontact.svg/" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
    merlinWebsite {
      websiteId
    }
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        contact_heading
        contact_description
      }
    }
    form: merlinForm(_id: { eq: "5f1da5b787c74d5c0b6f3f88" }) {
      _id
      name
      description
      meta {
        updatedTimestamp
      }
      sections {
        _id
        title

        multiple

        fields {
          label

          optional
          options {
            _id
            label
          }

          type
          id
          _id
        }
      }
    }
  }
`;
