export const API_ROUTE = getApiServer();
export const SITE = `${API_ROUTE}/client/siteData`;
export const FORM = `${API_ROUTE}/client/form`;
export const CONTACT = `${API_ROUTE}/client/contact`;
function getApiServer() {
  // if (process.env.NODE_ENV === "development") {
  //   return "http://localhost:3001";
  // }

  return "https://api.merlinpanel.com/v1";
}
